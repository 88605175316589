import React, {Component} from 'react';
import classes from './Subscribe.module.css';
import axios from "axios";
import ReactGA from "react-ga";

class Subscribe extends Component {

    state = {
        text: ""
    };

    onChange = (e) => {
        this.setState({text: e.target.value});
    };

    onSend = () => {
        axios.post("api/subscribers/store", JSON.stringify({...this.state}))
            .then(() => {
                this.setState({
                    text: ""
                });
                alert("Thank you for subscribing");
                ReactGA.event({
                    category: 'User',
                    action: 'Subscribe',
                });
            });


    };

    render() {
        return (
            <div className={classes.holder}>
                <input type="text" onChange={(e) => this.onChange(e)} placeholder={"E-Mail Address"}/>
                <button onClick={() => this.onSend()}>SUBSCRIBE</button>
            </div>
        );
    }
}

export default Subscribe;
