import React from 'react';

import classes from './NavItems.module.css';
import NavItem from "./NavItem/NavItem";
import locales from '../../../locales';

const NavItems = (props) => {
    const onChangeLanguage = ()=> {
        localStorage.lang = localStorage.lang == "en"?"tr":"en";
        window.location.reload();
    }
    return  (
        <ul className={classes.NavItems}>
            <NavItem active={props.active === "home"} closed={props.closed} link="#home">{locales.keyword.nav_home}</NavItem>
            <NavItem active={props.active === "about"} closed={props.closed} link="#about">{locales.keyword.nav_about}</NavItem>
            <NavItem active={props.active === "products"} closed={props.closed} link="#products">{locales.keyword.nav_product}</NavItem>
            <NavItem active={props.active === "contact"} closed={props.closed} link="#contact">{locales.keyword.nav_contact}</NavItem>
            {<NavItem closed={onChangeLanguage}  link="#home" highlight>{locales.keyword.change_lang}</NavItem>}
        </ul>
    )    
};


export default NavItems;