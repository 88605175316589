import React, {Component} from 'react';
import classes from './CareersForm.module.css';
import axios from "axios";
import ReactGA from "react-ga";
import locales from '../../locales';

class CareersForm extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileInput = React.createRef();
    }

    state = {
        file: {
            name: locales.keyword.contact_form_upload_label
        }
    };

    onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('cv', this.state.file);

        axios.post("api/direct-send", formData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        }).then(() => {
            this.setState({
                file: {
                    name: locales.keyword.contact_form_upload_label
                }
            });
            alert("We will get back to you soon");

            ReactGA.event({
                category: 'User',
                action: 'Careers form',
            });
        });
    };

    componentDidMount = () => {
        document.getElementById("careers_file").addEventListener("change", (e) => this.fileHandler(e));
    };

    fileHandler = (e) => {
        if (e.target.files.length > 0) {
            if ("application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf".indexOf(e.target.files[0].type) > 0) {
                document.getElementById("careers_label").innerText = e.target.files[0].name;
                this.setState({file: e.target.files[0]});
            }
        }
    };

    render() {
        return (
            <form onSubmit={this.onSubmit}>
                <p className={classes.paragraph}>
                    {locales.keyword.contact_form_description}</p>
                <input type="file" accept={"application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"}
                       onChange={this.onChange} hidden={"hidden"} id={"careers_file"}/>
                <div className={classes.inputGroup}>
                    <button className={classes.fileInput} id={"careers_label"} onClick={() => document.getElementById("careers_file").click()}
                            type={"button"}>{this.state.file.name}</button>
                </div>
                <div className={classes.inputGroup}>
                    <button className={classes.button} type={"submit"}>
                        {locales.keyword.contact_form_send_button}</button>
                </div>

            </form>
        );
    }
}

export default CareersForm;
