export default {
    nav_home:"Home",
    nav_about:"About Us",
    nav_product:"Products",
    nav_contact:"Contact Us",
    header_title:"Where there is a will",
    header_subtitle:"There is a way.",
    header_description:" Our will is sustainability.",
    about_wellcome:"Welcome To Vitriol Trade",
    about_paragraph0:`This is a platform uses the professional know how of the specialists in petrochemicals industry from various locations worldwide. Contribution of outnumbering interactive traders with their extensive suppliers and customers network of the industry evolved Vitriol from a local player to a global trading platform which creates an efficient rectification in fullfilling the objective.`,
    about_paragraph1:`The constant energy flow with infinite synergy in the structure launches the corporation to collaborate in a more consistent and efficient attitude on terms and conditions of purchase and sales agreements with our key suppliers and customers.`,
    about_paragraph2:`Under the flow of continuous feedback in specific regions we interconnect pursuant offers with pointed inquiries by targeting to achieve the ideal matches of the requirements of logistics, transportation, warehousing and financial tools of our bussiness associates.`,
    about_paragraph3:`Vitriol has its contractual partnership with reputable local and global logistics companies as well as financial entities in order to maximize the service to all its bussiness partners as we are proud of our sources consisted by well known, high profile, esteemed producers and our straight, trustworthy clients.
`,
    about_paragraph4:`Our headquarter is in Istanbul, which could be considered as a logical hub due to its allowance to keep close contact to leading suppliers and significant customers plus modern warehousing facilities. It derives us to coordinate all our tarding activities within our geographical covarage which are Turkey,  Middle East, Asia, USA and Africa, etc.. 
`,
    about_paragraph5:`Our Solidity is coming from our vintage background and our future will always be in diligent working towards a solution.
    `,
    products:{
        0: {
            name: "Plastics",
            children: {
                0: {
                    name: "Polyvinly Chloride (PVC)",
                    children: {
                        0: {
                            name: "* Suspension (S-PVC)"
                        },
                        1: {
                            name: "* Emulsion (E-PVC)"
                        }
                    }
                },
                1: {
                    name: "Styrenics",
                    children: {
                        0: {
                            name: "* General Purpose Polystyrene (GPPS)"
                        },
                        1: {
                            name: "* High Impact Polystyrene (HIPS)"
                        },
                        2: {
                            name: "* Expandable Polystyrene (EPS)"
                        }
                    }
                },
                2: {
                    name: "Polyethylene (PE)",
                    children: {
                         0: {
                            name: "* Yüksek Yoğunluklu Polietilen (HDPE)"
                        },
                        1: {
                            name: "* Alçak Yoğunluklu Polietilen (LDPE)"
                        },
                        2: {
                            name: "* Lineer Alçak Yoğunluklu Polietilen (LLDPE)"
                        }
                     
                    }
                },
                3: {
                    name: "Polypropylene (PP)",
                    children: {
                          0: {
                            name: "* Homopolymers (PPH)"
                        },
                        1: {
                            name: "* Copolymers (PPC)"
                        }                       
                    }
                }
            }
        },
        1: {
            name: "Chemicals",
            children: {
                0: {
                    name: "Acetates",
                    children: {
                        0: {
                            name: "* Ethyl Acetate (ETAC)"
                        },
                        1: {
                            name: "* N-Butyl Acetate (BUTAC)"
                        }
                    }
                },
                1: {
                    name: "Plasticizers",
                    children: {
                        0: {
                            name: "* Dioctyl Phtalate (DOP)"
                        },
                        1: {
                            name: "* Dioctyl Teraphtalate (DOTP)"
                        },
                        2: {
                            name: "* Epoxidized Soybean Oil (ESBO/ESO)"
                        }

                    }
                },
                2: {
                    name: "Glycols",
                    children: {
                          0: {
                            name: "* Monoethylene Glycol (MEG)"
                        },
                        1: {
                            name: "* Diethylene Glycol(DEG)"
                        }
                        
                    }
                },
                3: {
                    name: "Monomers",
                    children: {
                      0: {
                            name: "* Styrene Monomer (SM)"
                        },
                        1: {
                            name: "* Vinly Acetate Monomer (VAM)"
                        }                   
                    }
                }
            }
        }
    },
    contact_form_title_left:"Careers",
    contact_form_title_right:"Contact Us",
    contact_form_upload_label:"Please upload your CV",
    contact_form_description:`We are glad to welcome new team members on board who believe in working towards a solution to rise to the challenge which will help
    us to
    extend our business volume,
    service quality and geographical coverage.`,
    contact_form_send_button:"SEND",
    contact_form_name_error:"Please enter your name correctly",
    contact_form_company_error:"Please enter your company",
    contact_form_phone_error:"Please enter your phone correctly",
    contact_form_mail_error:"Please enter your mail correctly",
    contact_form_country_error:"Please enter your country",
    contact_form_message_error:"Please enter your message",
    contact_form_placeholder_name:"Your Name",
    contact_form_placeholder_company:"Company Name",
    contact_form_placeholder_phone:"Telephone Number",
    contact_form_placeholder_mail:"E-Mail Address",
    contact_form_placeholder_country:"Country",
    contact_form_placeholder_message:"Your Message",
    footer_title0:"Headquarters",
    footer_title1:"Turkey Office",
    change_lang:"TR", //Abi bu satır böyle kalsın,
    about_slogan:"Thrust you need to take the lead"
}
