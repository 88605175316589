import React from 'react';
import classes from "./ContactContainer.module.css";
import ContactForm from "../../components/ContactForm/ContactForm";
import CareersForm from "../../components/CareersForm/CareersForm";
import locales from '../../locales';

const ContactContainer = () => (
    <section className={classes.contact} id={"contact"}>
        <div className={classes.separator}>
            <div className={[classes.container, classes.reverse].join(" ")}>
                <h2 className={classes.header}>{locales.keyword.contact_form_title_left}</h2>
                <CareersForm onSubmit={()=>{}}/>
            </div>
            <div className={classes.container}>
                <h2 className={classes.header}>{locales.keyword.contact_form_title_right}</h2>
                <ContactForm/>
            </div>
        </div>
    </section>
);


export default ContactContainer;
