export default {
    nav_home:"Ana Sayfa",
    nav_about:"Hakkımızda",
    nav_product:"Ürünler",
    nav_contact:"İletişim",
    header_title:"Hedef varsa",
    header_subtitle:"Ona ulaşacak yol da vardır.",
    header_description:" Ufkunuzu genişletmeyi hedefliyoruz.",
    about_wellcome:"Vitriol Trade'e Hoş Geldiniz",
    about_paragraph0:`Vitriol, petrokimya endüstrisindeki uzmanların, dünya çapındaki çeşitli konumlardan, profesyonel bilgi birikimini kullanan bir platformdur. Sektörde geniş tedarikçi ve müşteri ağı olan ve birbiri ile sürekli etkileşim halinde olan traderların Vitriol’e katılımlarıyla, firmamız yerel bir oyuncudan hedefe odaklı küresel bir ticaret platformuna dönüşmüştür.`,
    about_paragraph1:`Yapımızdaki kusurusuz sinerjinin sağladığı sürekli enerji akışı, firmamızın, tedarikçiler ve müşterilerle yaptığı satın alma ve satış sözleşmelerinin hüküm ve koşulları üzerinde, daha istikrarlı ve verimli işbirliklerine ulaşmasını sağlamaktadır.`,
    about_paragraph2:`Sepsifik bölgelerden düzenli olarak aldığımız geri bildirimlerle, iş ortaklarımızın, lojistik, nakliye, depolama ve finansal konulardaki gereksinimlerine en ideal eşleşmeyi sağlayacak bağlantıları kurmaktayız.`,
    about_paragraph3:`Piyasalarda güvenilir ve saygın tedarikçi ve müşterilerimizden oluşan tüm iş ortaklarımıza  sağladığımız hizmeti en üst düzeye çıkarmak için tanınmış, yerel ve küresel lojistik şirketleri ve finansal kuruluşlarla sözleşmeli ortaklığımız vardır.`,
    about_paragraph4:`Merkezimiz, önde gelen tedarikçiler ve önemli müşterilerle ve modern depolama tesisleriyle yakın temas halinde olması nedeniyle stratejik bir merkez olarak kabul edilebilecek Istanbulda’dır. Turkiye, Orta Doğu, Asya, Amerika ve Afrika gibi coğrafi kapsama alanımızdaki tüm faaliyetlerimizi Istanbul’dan koordine etmekteyiz.
    `,
    about_paragraph5:`Gücümüz ve güvenilirliğimiz özgün geçmiş deneyimlerimize dayanmaktadır. Geleceğimiz de  her zaman hedeflenen çözüme ulaşmak için, özenle ve sabırla çalışılarak inşa edilecektir.`,
    products:{
        0: {
            name: "Polimerler",
            children: {
                0: {
                    name: "Polivinil Klorür (PVC)",
                    children: {
                        0: {
                            name: "* Süspansiyon (S-PVC)"
                        },
                        1: {
                            name: "* Emisyon (E-PVC)"
                        }
                    }
                },
                1: {
                    name: "Polistiren",
                    children: {
                        0: {
                            name: "* Genel Amaçlı Polistiren (GPPS)"
                        },
                        1: {
                            name: "* Yüksek Darbe Dayanımlı Polistiren (HIPS)"
                        },
                        2: {
                            name: "* Genleştirilmiş Polistiren (EPS)"
                        }
                    }
                },
                2: {
                    name: "Polietilen (PE)",
                    children: {
                          0: {
                            name: "* Yüksek Yoğunluklu Polietilen (HDPE)"
                        },
                        1: {
                            name: "* Alçak Yoğunluklu Polietilen (LDPE)"
                        },
                        2: {
                            name: "* Lineer Alçak Yoğunluklu Polietilen (LLDPE)"
                        }
                            
                    }
                },
                3: {
                    name: "Polipropilen (PP)",
                    children: {
                       0: {
                            name: "* Homopolimerler (PPH)"
                        },
                        1: {
                            name: "* Kopolimerler (PPC)"
                        }                  
                    }
                }
            }
        },
        1: {
            name: "Kimyasallar",
            children: {
                0: {
                    name: "Asetatlar",
                    children: {
                        0: {
                            name: "* Etil Asetat (ETAC)"
                        },
                        1: {
                            name: "* N-Butil Asetat (BUTAC)"
                        }
                    }
                },
                1: {
                    name: "Plastifiyanlar",
                    children: {
                        0: {
                            name: "* Dioktil Ftalat (DOP)"
                        },
                        1: {
                            name: "* Dioktil Tereftalat (DOTP)"
                        },
                        2: {
                            name: "* Epoksi Soya Yağı (ESBO/ESO)"
                        }

                    }
                },
                2: {
                    name: "Glikoller",
                    children: {
                          0: {
                            name: "* Monoetilen Glikol (MEG)"
                        },
                        1: {
                            name: "* Dietilen Glikol (DEG)"
                        }
                        
                    }
                },
                3: {
                    name: "Monomerler",
                    children: {
                       0: {
                            name: "* Stiren Monomer (SM)"
                        },
                        1: {
                            name: "* Vinil Asetat Monomer (VAM)"
                        }                  
                    }
                }
            }
        }
    },
    contact_form_title_left:"Kariyer",
    contact_form_title_right:"İletişim",
    contact_form_upload_label:"CV'nizi upload edin",
    contact_form_description:`İş hacmimizi, hizmet kalitemizi ve coğrafi kapsamımızı genişletmemizde bizimle beraber rol alacak yeni ekip üyelerini aramızda görmekten mutluluk duyuyoruz.`,
    contact_form_send_button:"Gönder",
    contact_form_name_error:"Lütfen doğru bir ad yazınız",
    contact_form_company_error:"Lütfen doğru bir şirket adı giriniz",
    contact_form_phone_error:"Lütfen doğru bir telefon numarası giriniz",
    contact_form_mail_error:"Lütfen doğru bir mail giriniz",
    contact_form_country_error:"Lütfen doğru bir ülke adı yazınız",
    contact_form_message_error:"Lütfen mesajınızı giriniz",
    contact_form_placeholder_name:"Adınız",
    contact_form_placeholder_company:"Firma Adı",
    contact_form_placeholder_phone:"Telefon Numarası",
    contact_form_placeholder_mail:"E-Mail Addresi",
    contact_form_placeholder_country:"Ülke",
    contact_form_placeholder_message:"Mesajınız",
    footer_title0:"Ana Merkez",
    footer_title1:"Türkiye Ofisi",
    change_lang:"EN", //Abi bu satır böyle kalsın,
    about_slogan:"Sektörde liderliğe taşıyan itici gücünüz"
}
