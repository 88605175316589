import React from 'react';
import classes from './ProductsContainer.module.css';
import Products from "../../components/Products/Products";
import locales from '../../locales';

const ProductsContainer = () => {

    const products = locales.keyword.products;

    return (
        <section className={classes.products} id={"products"}>
            <div className={classes.productsHolder}>
                <div className={classes.category}>
                    <h3 className={classes.reverse}>{products[0]["name"]}</h3>
                    <Products products={products[0]["children"]} reverse={true}/>
                </div>
                <div className={classes.category}>
                    <h3>{products[1]["name"]}</h3>
                    <Products products={products[1]["children"]}/>
                </div>
            </div>
        </section>
    );
};


export default ProductsContainer;
