import React, {Component} from 'react';
import classes from './ContactForm.module.css';
import Input from "../UI/Input/Input";
import axios from 'axios';
import ReactGA from "react-ga";
import locales from '../../locales';

class ContactForm extends Component {

    state = {
        name: {
            value: "",
            format: "^[A-Za-z\çöişğpÇÖİŞĞPİı]+([\\ A-Za-z\çöişüğÇÖİŞÜĞİı]+)*",
            message: locales.keyword.contact_form_name_error
        },
        company: {
            value: "",
            format: ".{1,100}",
            message: locales.keyword.contact_form_company_error
        },
        phone: {
            value: "",
            format: "^\\d{10,13}$",
            message: locales.keyword.contact_form_phone_error
        },
        mail: {
            value: "",
            format: "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])",
            message: locales.keyword.contact_form_mail_error
        },
        country: {
            value: "",
            format: ".{1,100}",
            message: locales.keyword.contact_form_country_error
        },
        message: {
            value: "",
            format: ".{1,500}",
            message: locales.keyword.contact_form_message_error
        }
    };

    valueChangeHandler = (e, type, index) => {
        let updatedState = {...this.state};
        updatedState[index].value = e.target.value;
        this.setState({...updatedState});
    };

    onSubmit = () => {

        let loop1Status = true;
        loop1:for (let param in this.state) {
            const reg = new RegExp(this.state[param].format);
            const test = reg.test(this.state[param].value);
            if (!test) {
                loop1Status = false;
                alert(this.state[param].message);
                break loop1;
            }
        }

        if (loop1Status) {

            const data = {
                name: this.state.name.value,
                company: this.state.company.value,
                phone: this.state.phone.value,
                mail: this.state.mail.value,
                country: this.state.country.value,
                message: this.state.message.value
            }

            axios.post("api/direct-send", JSON.stringify(data))
                .then(() => {
                    this.setState({
                        name: {
                            value: "",
                            format: "^[A-Za-z\çöişğpÇÖİŞĞPİı]+([\\ A-Za-z\çöişüğÇÖİŞÜĞİı]+)*",
                            message: locales.keyword.contact_form_name_error
                        },
                        company: {
                            value: "",
                            format: ".{1,100}",
                            message: locales.keyword.contact_form_company_error
                        },
                        phone: {
                            value: "",
                            format: "^\\d{10,13}$",
                            message: locales.keyword.contact_form_phone_error
                        },
                        mail: {
                            value: "",
                            format: "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])",
                            message: locales.keyword.contact_form_mail_error
                        },
                        country: {
                            value: "",
                            format: ".{1,100}",
                            message: locales.keyword.contact_form_country_error
                        },
                        message: {
                            value: "",
                            format: ".{1,500}",
                            message: locales.keyword.contact_form_message_error
                        }
                    });

                    alert("Thank you for contacting with us. We will get back to you soon");


                    ReactGA.event({
                        category: 'User',
                        action: 'Contact form',
                    });
                });
        }

    };

    render() {
        return (
            <form className={classes.contactForm}>
                <div className={classes.inputGroup}>
                    <Input placeholder={locales.keyword.contact_form_placeholder_name} type={"text"} value={this.state.name.value} onValueChange={this.valueChangeHandler} index={"name"}/>
                </div>
                <div className={classes.inputGroup}>
                    <Input placeholder={locales.keyword.contact_form_placeholder_company} type={"text"} value={this.state.company.value} onValueChange={this.valueChangeHandler} index={"company"}/>
                </div>
                <div className={classes.inputGroup}>
                    <Input placeholder={locales.keyword.contact_form_placeholder_phone} type={"tel"} value={this.state.phone.value} onValueChange={this.valueChangeHandler} index={"phone"}/>
                </div>
                <div className={classes.inputGroup}>
                    <Input placeholder={locales.keyword.contact_form_placeholder_country} type={"text"} value={this.state.country.value} onValueChange={this.valueChangeHandler} index={"country"}/>
                </div>
                <div className={classes.inputGroup}>
                    <Input placeholder={locales.keyword.contact_form_placeholder_mail} type={"text"} value={this.state.mail.value} onValueChange={this.valueChangeHandler} index={"mail"}/>
                </div>
                <div className={classes.inputGroup}>
                    <Input placeholder={locales.keyword.contact_form_placeholder_message} type={"text"} value={this.state.message.value} onValueChange={this.valueChangeHandler} index={"message"}/>
                </div>
                <div className={classes.inputGroup}>
                    <button type={"button"} onClick={() => this.onSubmit()}>{locales.keyword.contact_form_send_button}</button>
                </div>
            </form>
        );
    }
}

export default ContactForm;
