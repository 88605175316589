import React, {Component} from 'react';
import classes from './NavBar.module.css';
import Logo from '../../UI/Logo/Logo';
import NavItems from '../NavItems/NavItems';

import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';


class NavBar extends Component {

    state = {
        active: "home",
        fixed: false
    };


    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('load', this._getElementPositions);
    };

    handleScroll = () => {

        let currPosition = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);

        currPosition > 100 ? this.setState({fixed: true}) : this.setState({fixed: false});
        console.log(currPosition,this.state);

        if (currPosition < this.state.about - 200) {
            this.setState({active: "home"});
        } else if (currPosition < this.state.products - 200) {
            this.setState({active: "about"})
        } else if (currPosition < this.state.contact - 200) {
            this.setState({active: "products"})
        } else {
            this.setState({active: "contact"})
        }

    };

    _getElementPositions = () => {
        let sections = document.querySelectorAll("section");

        Array.prototype.forEach.call(sections, e => {
            let newState = {...this.state};
            newState[e.id] = e.offsetTop;
            this.setState(prevState => {
                return {...prevState, ...newState}
            });

        });
    };


    render() {
        return (
            <header className={this.state.fixed === true ? [classes.NavBar, classes.fixed].join(" ") : classes.NavBar}>
                <div className={classes.Logo}>
                    <Logo type={"header"}/>
                </div>
                <nav className={classes.DesktopOnly}>
                    <NavItems active={this.state.active}/>
                </nav>
                <DrawerToggle clicked={this.props.drawerToggleClicked}/>
            </header>
        );
    }
}

export default NavBar;
