import React from 'react';
import header from '../../../assets/logo-menu.png';
import footer from '../../../assets/logo-footer.png';
import classes from './Logo.module.css';

const Logo = (props) => {
    let img = null;
    switch (props.type) {
        case "header": {
            img = <a href="/"><img src={header} alt={"Vitriol Logo küçük"}/></a>;
            break;
        }
        case "footer": {
            img = <a href="/"><img src={footer} alt={"Vitriol Logo büyük"}/></a>;
            break;
        }
        case "noxarc": {
            img = <a href="http://www.noxarc.com" className={classes.noxarc} target={"_blank"}></a>;
            break;
        }
        default: {
            img = <a href="/"><img src={header} alt={"Vitriol Logo büyük"}/></a>;
            break;
        }
    }

    return (
        <div className={classes.Logo} style={props.style}>
            {img}
        </div>
    );
}


export default Logo;
