import React from 'react';
import Logo from '../../UI/Logo/Logo';
import NavigationItems from '../NavItems/NavItems';
import classes from './SideDrawer.module.css';
import Backdrop from "../../UI/Backdrop/Backdrop";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';

const SideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open]
    }
    return (
        <Auxiliary>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ')}>
                <div className={classes.Logo}>
                    <Logo height={"unset"}/>
                    <span onClick={props.closed} className={classes.dismiss}>X</span>
                </div>
                <nav>
                    <NavigationItems closed={props.closed}/>
                </nav>

            </div>
        </Auxiliary>
    );
}


export default SideDrawer;
