import React from 'react';
import classes from './SubProduct.module.css';

const SubProduct = (props) => (
    <div className={classes.subProduct}>
        {props.name}
    </div>
);


export default SubProduct;
