import React, {Component} from 'react';
import classes from './HomeContainer.module.css';
import VitriolVideo from "../../components/UI/Video/Video";
import locales from '../../locales';

class HomeContainer extends Component {

    state = {
        aboutPosition: 0
    }

    componentDidMount() {
        this.setState({aboutPosition: document.querySelector("#about").offsetTop});
    }

    render() {

        return (
            <section className={classes.home} id={"home"}>
                <VitriolVideo/>
                <div className={classes.contentContainer}>
                    <h4 className={classes.h4}>
                            {locales.keyword.header_title} 
                    </h4>
                    <h3 className={classes.h3}>
                            {locales.keyword.header_subtitle} 
                    </h3>
                    <h2 className={classes.h2}>
                            {locales.keyword.header_description} 
                    </h2>
                    <div onClick={() => window.scroll({top: this.state.aboutPosition, left: 0, behavior: 'smooth'})} className={classes.triangle}></div>
                </div>
            </section>
        );
    }
}

export default HomeContainer;
