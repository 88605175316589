import React from 'react';

import classes from './NavItem.module.css';

const NavItem = (props) => {
    let class_name = props.active === true ? classes.active : null;
    if(props.highlight)
        class_name = classes.highlight;
    return (
        <li className={classes.NavItem} onClick={props.closed}>
            <a className={class_name} href={props.link}>
                {props.children}
            </a>
        </li>
    );
}


export default NavItem;
