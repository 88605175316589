import React from 'react';
import classes from './Footer.module.css'
import Logo from "../UI/Logo/Logo";
import Subscribe from "../UI/Subscribe/Subscribe";
import Socials from "../UI/Socials/Socials";
import locales from "../../locales";

const Footer = () => (
    <footer className={classes.footer}>
        <div className={classes.content}>
            <div className={classes.logo}>
                <Logo type={"footer"} height={"83px"}/>
            </div>
            <div className={classes.info}>
                <span>VITRIOL TRADE FZCO</span>
                <span className={classes.mb10}>{locales.keyword.footer_title0}</span>
                <span>DWTC, Sheikh Rashid Tower,</span>
                <span>Floor 26 PO Box 92992</span>
                <span className={classes.mb10}>Dubai, UAE</span>
                <span>Mail: info@vitrioltrade.com</span>
            </div>
            <div className={classes.info}>
                <span>VITRIOL TRADE FZCO</span>
                <span className={classes.mb10}>{locales.keyword.footer_title1}</span>
                <span className={classes.mb10}>Istanbul, Turkey</span>
                <span>Tel: +90 532 340 5146</span>
                <span>Tel: +90 532 351 9495</span>

            </div>
            <div className={classes.info}>
            </div>
            <div className={classes.info}>
            </div>
            <div className={classes.subscribe}>
                <Subscribe/>
                <Socials/>
                <Logo type={"noxarc"} height={"11px"}/>
            </div>
        </div>
        <div className={classes.strip}>© {new Date().getFullYear()} Vitriol Trade. All Rights Reserved</div>
    </footer>
);


export default Footer;
