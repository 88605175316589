import React from 'react';
import classes from './Products.module.css';
import Product from "./Product/Product";

const Products = (props) => {


    let products = Object.keys(props.products).map(index => {

        return <Product key={index} name={props.products[index].name} children={props.products[index].children}/>

    });

    return (
        <div className={[classes.products, props.reverse === true ? classes.reverse : null].join(' ')}>
            {products}
        </div>
    );
};


export default Products;
