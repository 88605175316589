import React from 'react';
import classes from './Socials.module.css';
import SocialItem from "./SocialItem/SocialItem";

const Socials = (props) => (
    <div className={classes.social}>
        <SocialItem type={"twitter"} url={"https://twitter.com/vitrioltrade"}/>
        <SocialItem type={"instagram"} url={"https://www.instagram.com/vitriol.trade/"}/>
        <SocialItem type={"linkedin"} url={"https://www.linkedin.com/company/vitriol-trade/about/"}/>
        <SocialItem type={"facebook"} url={"https://www.facebook.com/vitrioltrade/"}/>
    </div>
);


export default Socials;
