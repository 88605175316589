import React, {Component} from 'react';
import Auxiliary from '../Auxiliary/Auxiliary';
import NavBar from "../../components/Navigation/Navbar/NavBar";
import Footer from "../../components/Footer/Footer";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";


class Layout extends Component {

    state = {
        showSideDrawer: false
    };

    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer: false});
    };

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return {showSideDrawer: !prevState.showSideDrawer};
        })
    };


    render() {
        return (
            <Auxiliary>
                <NavBar drawerToggleClicked={this.sideDrawerToggleHandler}/>
                <main>
                    {this.props.children}
                </main>
                <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler}/>
                <Footer/>
            </Auxiliary>
        )
    }
}

export default Layout;
