import React from 'react';
import classes from './AboutContainer.module.css'
import locales from '../../locales';

const AboutContainer = () => (
    <section className={classes.about} id={"about"}>
        <div className={classes.separator}>
        <div className={classes.div}>
            <h1 className={classes.h1}> {locales.keyword.about_wellcome}</h1>
            <h4 className={classes.h4}>
                {locales.keyword.about_slogan}
            </h4>
        </div>
        <div className={classes.div}>
            <p>
            {locales.keyword.about_paragraph0} 
            </p>
            <p>
            {locales.keyword.about_paragraph1} 
            </p>
            <p>
            {locales.keyword.about_paragraph2}
            </p>
        </div>
        <div className={classes.div}>
            <p>
            {locales.keyword.about_paragraph3} 
            </p> 
            <p>
                {locales.keyword.about_paragraph4} 
            </p>
            <p>
                {locales.keyword.about_paragraph5}
            </p>
        </div>
        </div>
    </section>
);


export default AboutContainer;
