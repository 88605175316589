import React from 'react';
import classes from './Video.module.css';
import videoHq from './../../../assets/hq.mp4';
import videoLq from './../../../assets/lq.webm';
import video414 from './../../../assets/414x744.mp4';
import video414Lq from './../../../assets/414x744.webm';
import video768 from './../../../assets/768x1208.mp4';
import video768Lq from './../../../assets/768x1208.webm';
import posterHQ from './../../../assets/vitriol-trade-back.jpg';
import posterMQ from './../../../assets/768-1208.jpg';
import posterLQ from './../../../assets/414-744.jpg';
import Auxiliary from "./../../../hoc/Auxiliary/Auxiliary";
import VideoComponent from "./VideoComponent";

const VitriolVideo = () => (
    <Auxiliary>
        <VideoComponent className={[classes.video, classes.highQ].join(' ')} src={[videoHq, videoLq]} poster={posterHQ}/>
        <VideoComponent className={[classes.video, classes.midQ].join(' ')} src={[video414, video414Lq]} poster={posterMQ}/>
        <VideoComponent className={[classes.video, classes.lowQ].join(' ')} src={[video768, video768Lq]} poster={posterLQ}/>
    </Auxiliary>
);


export default VitriolVideo;
