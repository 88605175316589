import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import ReactGA from 'react-ga';


//Axios default configs
axios.defaults.baseURL = "https://www.vitriol.viwou.info/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.interceptors.request.use(request => {
    console.log(request);

    return request
}, error => {
    console.log(error);

    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    console.log(response);

    return response
}, error => {
    console.log(error);

    return Promise.reject(error);
});


//GA init

ReactGA.initialize('UA-132980952-1');
ReactGA.pageview(window.location.pathname + window.location.search);


ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
