import React, {Component} from 'react';
import Layout from "./hoc/layout/layout";
import HomeContainer from "./containers/HomeContainer/HomeContainer";
import AboutContainer from "./containers/AboutContainer/AboutContainer";
import ProductsContainer from "./containers/ProductsContainer/ProductsContainer";
import ContactContainer from "./containers/ContactContainer/ContactContainer";
import Footer from './components/Footer/Footer';

class App extends Component {
    componentDidMount(){
    
    }
    render() {
        return (
            <Layout>
                <HomeContainer/>
                <AboutContainer/>
                <ProductsContainer/>
                <ContactContainer/>
            </Layout>
        );
    }
}

export default App;
