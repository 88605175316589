import * as React from 'react';
import {Component} from 'react';

export default class VideoComponent extends Component {
    videoContainer = HTMLDivElement;

    componentDidMount() {
        const video = document.createElement('video');
        video.autoplay = true;
        video.loop = true;
        video.muted = true; // fixes autoplay in chrome
        video.defaultMuted = true; // fixes autoplay in chrome
        video.poster = this.props.poster;
        video.setAttribute('playsinline', ""); // fixes autoplay in webkit (ie. mobile safari)
        video.setAttribute("class", this.props.className);

        const sourceHQ = document.createElement('source');
        sourceHQ.src = this.props.src[0];
        sourceHQ.type = "video/mp4";
        video.appendChild(sourceHQ);

        const sourceLQ = document.createElement('source');
        sourceLQ.src = this.props.src[1];
        sourceLQ.type = "video/webm";
        video.appendChild(sourceLQ);


        this.videoContainer.appendChild(video);

        document.addEventListener("load", () => {
            video.setAttribute("controls", "true");
            let playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise.then(() => {
                    // Automatic playback started!
                    video.removeAttribute("controls");
                }).catch(function (error) {
                    // Automatic playback failed.
                    // Show a UI element to let the user manually start playback.
                    video.removeAttribute("controls");
                    console.log(1, error)
                });
            }
        })


    }

    render() {
        return (
            <div ref={(ref) => {
                this.videoContainer = ref;
            }}/>
        );
    }
}
