import React from 'react';
import classes from './SocialItem.module.css';


const SocialItem = (props) => {

    let icon = null;
    switch (props.type) {
        case "twitter": {
            icon = <a target={"_blank"} className={classes.twitter} href={props.url}></a>;
            break;
        }
        case "instagram": {
            icon = <a target={"_blank"} className={classes.instagram} href={props.url}></a>;
            break
        }
        case "linkedin": {
            icon = <a target={"_blank"} className={classes.linkedin} href={props.url}></a>;
            break
        }
        case "facebook": {
            icon = <a target={"_blank"} className={classes.facebook} href={props.url}></a>;
            break
        }
        default: {
            return null;
        }
    }

    return (
        <span className={classes.social} style={{height: props.height}}>
            {icon}
        </span>
    );
};


export default SocialItem;
