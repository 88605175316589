import React from 'react';
import classes from './Input.module.css';

const Input = (props) => {

    let input = null;
    switch (props.type) {
        case "text": {
            input = <input type={"text"} name={props.name} value={props.value} onChange={(e) => props.onValueChange(e, props.type, props.index)} className={classes.input}
                           placeholder={props.placeholder}/>;
            break;
        }
        case "email": {
            input = <input type={"email"} name={props.name} value={props.value} onChange={(e) => props.onValueChange(e, props.type, props.index)} className={classes.input}
                           placeholder={props.placeholder}/>;
            break;
        }
        case "tel": {
            input = <input type={"tel"} name={props.name} value={props.value} onChange={(e) => props.onValueChange(e, props.type, props.index)} className={classes.input}
                           placeholder={props.placeholder}/>;
            break;
        }
        default: {
            input = <input type={"text"} name={props.name} value={props.value} onChange={(e) => props.onValueChange(e, props.type, props.index)} className={classes.input}
                           placeholder={props.placeholder}/>;
            break;
        }
    }

    return input;
};


export default Input;
