import React from 'react';
import classes from './Product.module.css';
import SubProduct from "./SubProduct/SubProduct";

const Product = (props) => {


    let products = Object.keys(props.children).map(index => {

        return <SubProduct key={index} name={props.children[index].name} children={props.children[index].children}/>

    });

    return (
        <div className={classes.product}>
            <p>{props.name}</p>
            {products}
        </div>
    );
}


export default Product;
